import { COOKIES } from '~/constants'

export default function () {
  const adminCookie = useCookie(COOKIES.adminAccessToken)

  const isMasquerading = computed(() => Boolean(adminCookie.value))

  return {
    isMasquerading,
  }
}
