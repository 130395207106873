/**
 * Returns the bot score from the request headers (if present).
 *
 * Bot scores are calculated by Cloudflare:
 * https://developers.cloudflare.com/bots/concepts/bot-score/#bot-groupings
 * Scores above 0 and lower than 30 are considered potentially a bot.
 */
export default function useBotScore() {
  const headers = useRequestHeaders()

  const botScoreHeader = headers['X-Bot-Score'] || '0'
  const botScore = Number(botScoreHeader)

  const isPotentiallyABot = (botScore <= 29 && botScore > 0)

  return {
    isPotentiallyABot,
  }
}
